@media(max-width : 600px){
    .No_Data_Found_Container img{
        width: 250px !important;
    }
}
.No_Data_Found_Container{
    display: flex;
    justify-content: center;
    width: 100%;
}

.no_category_present{
    display: flex;
    flex-direction: column;
    justify-content: left;
    align-items: center;
}
.bigProduct_item{
    height: 470px;
    border-radius: 15px;
    overflow: hidden;
    position: relative;
}
.bigProduct_item img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.bigProduct_content h4 {
    font-size: 34px;
    line-height: 45px;
    text-transform: capitalize;
}
.bigProduct_content{
    position: absolute;
    top: 45px;
    left: 40px;
    max-width: 330px;
}
.bigProduct_slider .swiper-pagination {
    bottom: -10px;
}
.paddingTop{
    padding-top: 50px;
}
.paddingBottom{
    padding-bottom: 50px;
}
.swiper-pagination-bullet{
    width: 9px;
    height: 9px;
    border: 1px solid var(--black-color) !important;
    opacity: 1;
    background-color: var(--white-color);
}
.swiper-pagination-bullet.swiper-pagination-bullet-active {
    background: var(--theme-color);
    border: 0 !important;
}
.swiper-pagination {
    left: 50% !important;
    transform: translate(-50%, -50%);
    z-index: 10;
    width: fit-content !important;
    background: var(--white-color);
    padding: 0px 8px 1px;
    border-radius: 100px;
}
.product-btn {
    border: none;
  }
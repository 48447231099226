/* .App-logo {
  height: 40vmin;
  pointer-events: none;
}

.contextMenu_view {
  display: block;
  border-radius: 4px;
  box-sizing: border-box;
  position: absolute;
  width: 200px;
  z-index: 99;
}
.contextMenu_hide {
  display: none;
  border:none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
} */
:root {
  --theme-color: #13a753;
  --darkblue-color: #003349;
  --black-color: #222222;
  --white-color: #ffffff;
  --grey-color: #6d6d6d;
  --gold-color: #c89e2f;
}

* {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
body {
  font-weight: 400;
  font-family: "FK Grotesk";
  overflow-x: hidden;
}

html {
  scroll-behavior: smooth;
}

.scrollbar {
  overflow-y: scroll;
}

::-webkit-scrollbar-track
{
background-color: var(--white-color);
  border-radius: 100px;
}

::-webkit-scrollbar
{
width: 5px;
  height: 5px;
}

::-webkit-scrollbar-thumb
{
background-color: #464646;
  border-radius: 100px;
}
.App {
  background-color: #ffffff;
}

@font-face {
  font-family: "FK Grotesk";
  src: url("../src/utils/fonts/FKGrotesk-Regular.woff") format("woff2"),
    url("../src/utils/fonts/FKGrotesk-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "FK Grotesk";
  src: url("../src/utils/fonts/FKGrotesk-Medium.woff2") format("woff2"),
    url("../src/utils/fonts/FKGrotesk-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "FK Grotesk";
  src: url("../src/utils/fonts/FKGrotesk-Bold.woff2") format("woff2"),
    url("../src/utils/fonts/FKGrotesk-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
.fw-500 {
  font-weight: 500 !important;
}
.fw-bold {
  font-weight: bold !important;
}
.black_clr {
  color: var(--black-color);
}
.darkblue_clr {
  color: var(--darkblue-color);
}
.grey_clr {
  color: var(--grey-color);
}
.gold_clr {
  color: var(--gold-color);
}
.green_clr {
  color: var(--theme-color);
}
/* ==== BG Classes ==== */
.bg_light_yellow {
  background-color: #fcf4f0;
}

.bg_grey {
  background-color: #f3f4f6;
}

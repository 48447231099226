.added_prod {
  cursor: default;
}
.address_wrap {
  display: flex;
  align-items: center;
}

.address_wrap:hover {
  background-color: rgba(154, 233, 154, 0.151);
}

.address_wrap input[type="radio"] {
  margin-right: 10px;
  max-width: fit-content;
}
.address_wrapper {
  /* padding: 0 25px 25px 25px; */
  border-radius: 23px;
  max-height: 50vh;
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  -webkit-box-shadow: -2px 2px 5px 0px rgba(156, 166, 158, 1);
  -moz-box-shadow: -2px 2px 5px 0px rgba(156, 166, 158, 1);
  box-shadow: -2px 2px 5px 0px rgba(156, 166, 158, 1);
}
.details_wrapper {
  display: flex;
  gap: 5px;
}
.cross_btn {
  border: none;
  background-color: white;
}
.row-wrapper {
  margin-bottom: 100px;
}
.totalPrice_wrap {
  padding: 20px;
  border-radius: 23px;
}
.product-wrapper {
  overflow-y: scroll;
  max-height: 35vh;
  padding: 10px;
  margin-bottom: 10px;
}
.radio_btn_wrap {
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
}
.po_number_input_field {
  outline: 0.5px solid black !important;
  border-radius: 10px;
  margin-bottom: 10px;
  max-height: 6.5vh;
}
.remarks_input_field {
  outline: 0.5px solid black !important;
  border-radius: 10px;
  margin-bottom: 10px;
  max-height: 6.5vh;
}
.total_wrp {
  border-top: 1px solid #d0d0d0;
  padding-top: 20px;
}
.cancel_order_btn{
  background-color: grey !important;
}
.cancel_order_btn:hover{
  background-color: rgb(187, 27, 27) !important;
}
.anchor_link {
  border: none;
  background-color: white;
  /* margin: 0 auto; */
}
.show_btn {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}
.shipping_add {
  position: sticky;
  top: 0px;
  padding-top: 25px;
  padding-left: 15px;
  background-color: white;
  z-index: 2;
}
.breadCrumb {
  position: sticky;
  top: 0px;
  background-color: white;
  z-index: 2;
}
.label_wrapper {
  padding: 15px 5px 15px 15px;
  width: 100%;
}
.label_wrapper:hover {
  cursor: pointer;
}
.edit_delete_options {
  float: right;
  display: flex;
  gap: 5px;
}

.address_wrapper_shipping {
  border-radius: 23px;
  max-height: 50vh;
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  -webkit-box-shadow: -2px 2px 5px 0px rgba(156, 166, 158, 1);
  -moz-box-shadow: -2px 2px 5px 0px rgba(156, 166, 158, 1);
  box-shadow: -2px 2px 5px 0px rgba(156, 166, 158, 1);
  width: 55vw;
}

.label_wrapper_shipping {
  padding: 15px 5px 15px 15px;
  width: 54vw;
}
.sub_address_wrap {
  max-width: 38rem;
}
.shipping-address {
  width: 100%;
}
.button-wrapper{
  display: flex;
  justify-content: space-between;
}
.add-new-address-btn{
  margin-bottom: 10px;
  float: right;
  margin-right: 9vw;
}
.shipping-address-wrapper:hover{
  cursor: pointer;
}
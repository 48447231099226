@media (max-width : 600px) {
    .SignUp-inner-container {
        width: 92vw !important;
        margin-top: 10px !important;
        overflow-y: scroll !important;

    }

    #signUp-logo {
        margin-top: 15px !important;
    }

    #signUp-logo img {
        width: 22vw !important;
    }

    #SignUp-title {
        font-size: 1em !important;
        margin-bottom: 0px;
        margin-top: 15px;
    }

    #register-btn {
        width: 32vw !important;
        height: 5vh !important;
    }

    .userField-inner-Container {
        display: block !important;
    }

}

@media screen and (min-width:601px) and (max-width:1300px) {
    .SignUp-inner-container {
        width: 72vw !important;
    }

    #register-btn {
        width: 14vw !important;
        height: 4.5vh !important;
    }

    #SignUp-title {
        font-size: 2.5vw !important;
    }

    #signUp-logo img {
        width: 16vw !important;
        height: 8vh !important;
    }

}

.SignUp-container {
    width: 100vw;
    height: 100vh;
    background-repeat: no-repeat;
    background-size: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* background-image: url('../../../Components/Images/Section1BG.jpg'); */
    text-align: center;
}

#signUp-logo {
    margin-top: 10px;
}

#signUp-logo img {
    height: 6vh;
    width: 8vw;
}

#SignUp-title {
    font-family: 'fangsong';
    font-size: 1.5vw;
}

.disabled_filled {
    margin: 1%;
    width: 9rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #DEDEDE;
    color: #8a7f7f;
    border-radius: 5px;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 40px;
    padding-right: 40px;
}

.SignUp-inner-container {
    border: 1px solid rgb(221, 209, 209);
    border-radius: 10px;
    margin: 20px;
    /* background-color: white; */
    display: flex;
    flex-direction: column;
    margin: 20px auto;
    margin-top: 0;
    text-align: center;
    width: 45vw;
    background: white;
    overflow-y: scroll !important;


}

.SignUp-inner-container h1 {
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', 'Geneva', Verdana, sans-serif;
    font-size: 6vh;
}

.User-fields-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.user-field {
    display: flex;
    flex-direction: column;
    margin: 2px auto;
    margin-top: 10px;
}

.userField-inner-Container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

.user-info-field {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin: 5px;
}

.user-info-field .MuiTextField-root {
    width: 100%;
}


#register-btn {
    background-color: rgb(17, 202, 17);
    border: none;
    width: 10vw;
    height: 2.5vw;
    color: white;
    font-size: 15px;
    border-radius: 5px;
    margin: 15px;
    cursor: pointer;
}
#disabled {
    background-color: #8a7f7f;
}

#go-to-login {
    text-decoration: underline;
    margin-top: 1px;
    cursor: pointer;
    color: blue;
}
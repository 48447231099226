@keyframes skeleton-animation
{
  
  0%
  {
    right: 100%;
  }
  
  50%
  {
    right: -128px;
  }
  
  100%
  {
    right: 100%;
  }
  
}

.skeleton
{
  overflow: hidden;
  width: 328px;
  height: 78px;
  display: grid;
  grid-template-columns: 128px 200px;
  grid-gap: 8px;
  position: relative;
  margin-bottom: 16px;
}

.skeleton:before
{
  display: block;
  position: absolute;
  width: 128px;
  height: 78px;
  background: linear-gradient(90deg, rgba(255, 255, 255, 0), #FFF, rgba(255, 255, 255, 0));
  content: "";
  animation: skeleton-animation 1.25s infinite;
}



.skeleton .s-line
{
  height: 20px;
  background: #eee;
}

.skeleton .s-line.first { width: 100% }

.skeleton .s-line.second { width: 95% }

.skeleton .s-line.third { width: 70% }
.top_footerpart {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding-bottom: 40px;
    margin-bottom: 40px;
    column-gap: 50px;
    border-bottom: 1px solid #E1E1E1;
}
.leftpart_topfooter{
    max-width: 500px;
}
.subscribe_form{
    display: flex;
    align-items: center;
}
.subscribe_form input{
    border: 0;
    outline: none;
    background-color: #F3F4F6;
    color: #7B8488;
    font-size: 16px;
    min-width: 500px;
}
.border-r0{
    border-radius: 0px !important;
}
.fs-10{
    font-size: 12px;
}
.fs-14{
    font-size: 14px;
}
.footer_links{
    padding: 0;
    margin: 0;
}
.footer_links li{
    list-style: none;
    margin-bottom: 10px;
}

.footer_links li a{
    color: var(--black-color);
    text-decoration: none;
    font-size: 16px;
    text-transform: capitalize;
    transition: .2s all;
}
.footer_links li a:hover{
    color: var(--theme-color);
}

.copyright_text{
    border-top: 1px solid #E1E1E1;
}
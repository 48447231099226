
:root{
  --theme-color: #13A753;
  --darkblue-color: #003349;
  --black-color: #222222;
  --white-color: #FFFFFF;
  --grey-color: #6D6D6D;
  --gold-color: #C89E2F;
}


*{
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
-moz-box-sizing: border-box;
box-sizing: border-box;
}
body{
  font-weight: 400;
  font-family: 'FK Grotesk';
  overflow-x: hidden;
}

html {
scroll-behavior: smooth;
}

.scrollbar{
  overflow-y: scroll;
}

.scrollbar::-webkit-scrollbar-track
{
background-color: var(--white-color);
  border-radius: 100px;
}

.scrollbar::-webkit-scrollbar
{
width: 5px;
  height: 5px;
}

.scrollbar::-webkit-scrollbar-thumb
{
background-color: #464646;
  border-radius: 100px;
}

@media (max-width: 991.98px) {
  .table-scroll{
      padding: 0px 0px;
      font-size: 14px;
      overflow-y: scroll;
      overflow-x: scroll;
      height: 35vh !important;

  }
.border_r20 {
  border-radius: 20px;
}
.coupon_wrap {
  display: flex;
  align-items: center !important;
  justify-content: center !important;
  column-gap: 10px !important;
}

 
}
.cart_total_wrap {
  /* min-width: 450px; */
  width: 90%;
  -webkit-box-shadow: -2px 2px 5px 0px rgba(156, 166, 158, 1);
  -moz-box-shadow: -2px 2px 5px 0px rgba(156, 166, 158, 1);
  box-shadow: -2px 2px 5px 0px rgba(156, 166, 158, 1);
}
.delete_productBtn {
  text-align: center;
  cursor: pointer;
}

.cartProduct_img {
  width: 100px;
  height: 100px;
  border-radius: 10px;
  border: 1px solid #dcdcdc;
  margin-right: 10px;
  overflow: hidden;
}
.cartProduct_img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.default_table tbody tr td .quantity-number {
  width: 160px;
  padding: 12px 12px;
}
.coupon_form {
  display: flex;
  align-items: center;
  column-gap: 10px;
}
.default_form.coupon_form input {
  width: 300px;
}
.coupon_wrap {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  column-gap: 20px;
}

.shippingBtn_wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 20px;
  padding-top: 35px;
  padding-bottom: 35px;
  margin-bottom: 35px;
  border-bottom: 1px solid #dcdcdc;
}
.links_wrapper {
  display: flex;
  align-items: center;
  column-gap: 20px;
}

.totalPrice_wrap {
  padding: 40px;
  border-radius: 23px;
}

.totalPrice_wrap .cartProduct_img {
  width: 70px;
  height: 70px;
}
.productItem_count {
  position: relative;
}
.productItem_count span {
  top: -6px;
  right: 3px;
  background-color: var(--theme-color);
  color: var(--white-color);
  padding: 3px 6px;
  border: 1px solid #6a6a6a;
  font-size: 12px;
  border-radius: 100px;
  min-width: 20px;
  position: absolute;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.added_productItem {
  text-decoration: none;
  color: var(--black-color) !important;
  margin-bottom: 25px;
}
.totalPrice_wrap .added_productItem:last-child {
  margin-bottom: 0px;
}

.totalPrice_wrap .default_form.coupon_form {
  padding: 20px 0px;
  margin-bottom: 20px;
  border-top: 1px solid #d0d0d0;
  border-bottom: 1px solid #d0d0d0;
}

.total_price {
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 10px;
  padding-top: 20px;
  border-top: 1px solid #d0d0d0;
}

.bottom_menuLinks {
  display: none;
}
.offcanvas-top {
  padding: 10px 0px;
  height: max-content;
}

.quantity-number {
  width: 170px;
  text-align: center;
  padding: 15px 15px;
  border: 1px solid rgb(197, 197, 197);
  border-radius: 10px;
  background: var(--white-color);
}
.quantity-number span {
  cursor: pointer;
  color: var(--black-color);
  font-size: 17px;
}
.quantity-number input {
  color: var(--black-color);
  margin: 0px 10px;
  width: 60px;
  border: 0px;
  font-weight: 500;
  font-size: 16px;
}
.quantity-number input:focus {
  outline: none;
}
.default_table.table > :not(caption) > * > * {
  vertical-align: middle;
  padding: 20px 15px;
}
.cart_table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
}
th, td {
  padding: 10px;
  text-align: left; /* Align content left */
}
.table-hover > tbody > tr:hover {
  --bs-table-accent-bg: rgb(238, 255, 245);
  color: var(--black-color);
}
.clear_btn {
  border-radius: 10px;
  background-color: #f3f4f6;
}
.clear_btn:hover {
  background-color: #13a753;
  color: white;
}
.btn_grp {
  gap: 5px;
}
/* .cart_total_wrapper {
  position: ;
} */
.table_wrapper {
  flex-direction: row;
}

.table-scroll {
  width: max-content;
  height: 85vh;
  overflow-y: scroll;
  overflow-x: scroll;
}

.table-scroll::-webkit-scrollbar {
  display: none;
}
.table_head {
  position: sticky;
  top: 0px;
  background-color: white;
  border-bottom: 2px solid green;
}
.cart_empty {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 50px;
}
button:disabled{
  background-color: #6a6a6a;
  cursor: not-allowed;
}
.prod-details-wrapper{
  width: fit-content;
}
.cart_icons{
  cursor: pointer;
  size: 10px;
  font-weight: bold;
  /* margin-top: 5px */
}
.edit_quantity{
  font-size: 12px;
}
.required{
  color: red;
}

.download_catalog{
  /* height: 1.5rem; */
  padding-left: 0.4rem;
  padding-right: 0.3rem;
  color: white;
  text-decoration: underline;
  color: #13a753;
  /* background-color: #13a753; */
}
.download_catalog:hover{
  color: #13a753;
  /* background-color: #13a753; */
}
.border_r20 {
  border-radius: 20px;
}

.otp-container {
    display: flex;
    justify-content: center;
  }
  
  .otp-input:focus {
    height: 40px;
    font-size: 24px;
    text-align: center;
    margin: 0 5px;
    border: 1px solid #000;
    border-radius: 5px;
    /* outline: ; */
  }
  /* To prevent resizing */
  .otp-input::-webkit-inner-spin-button,
  .otp-input::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  .otp-input {
    width: 60px !important;
    border-radius: 5px;
    height: 40px;
    margin: 2px;
    border: 1px solid black !important;
    text-align: center;
  }
  
  .btn-center {
    display: flex;
    justify-content: center;
    gap: 50px;
  }
  
  
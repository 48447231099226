.forms_wrpapper{
    max-width: 800px;
    margin: auto;
}
.default_tabs .nav-pills .nav-link{
    font-size: 25px;
    font-weight: bold;
    color: #C3C3C3;
}
.default_tabs .nav-pills .nav-link.active {
    cursor: default !important;
    color: #fff;
    background-color: transparent;
    color: var(--black-color);
}
.default_form input , .default_form select {
    border: 0;
    outline: none;
    background-color: #F3F4F6;
    color: #7B8488;
    font-size: 16px;
    transition: .3s all;
    border-radius: 7px;
    width: 100%;
    padding: 15px 25px;
    border: 1px solid #F3F4F6;
    margin-bottom: 15px;
}
.default_form input::placeholder{
    color: #9e9e9e;
    transition: .3s all;
}
.default_tabs .nav-pills{
    column-gap: 35px;
}

.default_form input:focus::placeholder{
    opacity: .4;
}
.remember_para{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.default_form input[type="checkbox"], .default_form input[type="radio"] {
    min-width: 16px;
    height: 16px;
    width: 16px !important;
    margin: inherit;
    border: 1px solid #C3C3C3;
    padding: inherit;
}
.anchor_link{
    color: var(--theme-color);
    font-size: 14px;
    text-decoration: none;
    transition: .2s all;
    font-weight: 500;
    border: none;
    background: none;
}
.anchor_link:hover{
    color: var(--theme-color);
    text-decoration: underline;
}
.page_path{
    font-size: 16px;
    margin-bottom: 0px;
    margin-top: 20px;
    font-weight: 500;
}
.page_path a{
    font-weight: 400;
    color: #7F7F7F;
    text-decoration: none;
}
.page_path a:hover{
    color: var(--theme-color);
}

.show-password{
    position: absolute;
    float: right;
    right: 30vw;
    margin-top: 1.5vh;
    cursor: pointer;
}
.remember-btn{
    cursor: pointer;
}
@media (max-width: 575.98px) {
  .table-fixed {
  width: 100vw !important;
  height: 41vh ;
  overflow-y: scroll;
  overflow-x: scroll !important;

}
.fs-14 {
  font-size: 7px !important;
}
.css-10mg1vw-MuiStepper-root {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-align-items: flex-start;
  -webkit-box-align: flex-start;
  -ms-flex-align: flex-start;
  align-items: flex-start;
  overflow-x: scroll;
  /* overflow-x: hidden; */
}
}
.add-to-cart-input {
  width: 80%;
}
.table-fixed {
  width: 65vw;
  height: 65vh;
  overflow-y: scroll;
  overflow-x: hidden;
}
.Ordertable_head {
  position: sticky;
  top: 0px;
  background-color: white;
  outline: 1px solid green;
  /* border:1px solid green */
}
.no-data-found {
  width: 31.25rem;
}
.image-pos {
  display: flex;
  justify-content: center;
  align-items: center;
}
.table-wrap{
  max-width: fit-content;
}

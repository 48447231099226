@media (max-width: 991.98px) {
  .container {
      max-width: 100%;
  }
}

@media (max-width: 575.98px) {
  @media (max-width: 416.98px) {
      .bordered_btn,.animated-view-more .product_name, .product_price, .product_current_price {
          font-size: 13px !important;
      }
      .addCart_btn .bordered_btn{
        border-radius: 15px !important;
        font-size: 10px !important;
        padding: 10px 3px !important
      }
      
  }
}
@media (max-width: 575.98px) {
  .product_img img {
      object-fit: cover;
      height: 100% !important;
  }
  .product_img  {
    object-fit: cover;
    height: 50% !important;
}
  .mob{
    height: 100% !important;
    padding: 10px 10px !important;
    margin:  8px 2px 7px 2px !important;
    border: 1px solid #d9d9d9;
    padding: 6px 14px !important; 
    display: block !important;
  }
  
  .product_name {
        font-size: 12px !important;
  }
  .CartBtn{
    border-radius: 15px !important;
    font-size: 10px !important;
    padding: 10px 3px !important
  }
  .text{
    font-size: 9px !important;
  }
  .animated-view-more {
    border-radius: 15px !important;
    font-size: 11px !important;
    padding: 10px 12px;
  }
  .printedWeight{
    display: none;
   

  }
  .excl-gst-price {
    color: var(--theme-color);
    font-size: 9px !important;   
}
.excl-gst-text, .mrp-text {
  font-size: small;
  font-size: 9px !important;   

}

  /* .paddingBottom {
    padding: 0px !important;
  } */

}
ul.filter_links {
  padding: 0;
  margin: 0;
}
ul.filter_links li {
  list-style-type: none;
  margin-bottom: 9px;
}
ul.filter_links li:last-child {
  margin-bottom: 0px;
}
ul.filter_links li a {
  text-transform: capitalize;
  text-decoration: none;
  transition: 0.2s all;
  color: var(--grey-color);
}
ul.filter_links li a:hover {
  color: var(--black-color);
}

#filterAccordion .accordion-body {
  padding: 2px 10px 0px 10px;
}
#filterAccordion .accordion-button {
  text-transform: capitalize;
  box-shadow: none !important;
  border-bottom: 0px;
  padding: 0px 10px 10px 10px;
  font-weight: 500;
  font-size: 18px;
}
#filterAccordion .accordion-button:not(.collapsed) {
  color: var(--black-color);
  background-color: var(--white-color);
  box-shadow: none;
}

#filterAccordion .accordion-item {
  margin-top: 25px;
  border: 0px;
}

#filterAccordion .accordion-button::after,
.product_allDetails .accordion-button::after {
  width: 16px;
  height: 16px;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  background-size: 16px;
}
#filterAccordion .accordion-body .form-check {
  margin-bottom: 5px;
}
#filterAccordion .accordion-body .form-check:last-child {
  margin-bottom: 0px;
}
#filterAccordion .accordion-body .form-check label {
  text-transform: capitalize;
  transition: 0.2s all;
  cursor: pointer !important;
  display: inline-flex;
  align-items: center;
  column-gap: 10px;
  color: var(--grey-color);
}
#filterAccordion .accordion-body .form-check label:hover {
  color: var(--black-color);
}
#filterAccordion .accordion-body .form-check label .form-check-input {
  border-color: #bebebe;
  box-shadow: none;
  width: 20px;
  height: 20px;
  margin-top: -2px;
}
#filterAccordion .accordion-body .form-check label .form-check-input:checked {
  background-color: var(--theme-color);
  border: 0px;
}

.appliedFilter_wrapper {
  margin-top: 25px;
  margin-bottom: 25px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  column-gap: 10px;
  flex-wrap: wrap;
  row-gap: 10px;
}

.applied_filter {
  transition: 0.2s all;
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 20px;
  font-size: 14px;
  cursor: pointer;
  border: 1px solid var(--black-color);
  padding: 8px 12px;
  border-radius: 10px;
}
.applied_filter:hover {
  color: rgb(231, 48, 48);
  border-color: rgb(231, 48, 48);
}

.shortBy_wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid var(--black-color);
  border-radius: 10px;
  padding: 7px 15px;
  margin-bottom: 20px;
}
.shortBy_wrapper select {
  cursor: pointer !important;
  font-weight: 500;
}
.hero_slider.productPage_slider .swiper-slide img {
  height: 310px;
}

.hero_slider.productPage_slider .heroSlider_content h1 {
  font-size: 32px;
}
.hero_slider.productPage_slider .heroSlider_content h4 {
  font-size: 18px;
}
.add-to-cart-input {
  /* width: 50%; */
  border-top: #000000;
  border-left: #000000;
  border-right: #000000;
  outline: none;
  text-align: center;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

.product_current_price {
  width: 13rem;
  color: #212529;
}

.printedWeight {
  float: right;
  color: #212529;
  font-size: 16px !important;
  font-weight: 500;
  text-align: end;
}

.filters{
  background-color: gainsboro;
  width: fit-content;
  padding: 3px;
  border-radius: 2px;
}

.excl-gst-price {
  color: var(--theme-color);
  font-weight: 600;
}

.excl-gst-text,
.mrp-text {
  font-size: small;
}

.back-to-top-button {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #13a753;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 0px 0px 4px rgba(180, 160, 255, 0.253);
  cursor: pointer;
  transition-duration: 0.3s;
  overflow: hidden;
  position: fixed;
  bottom: 5%;
  right: 2%;
}

.hiddenBtn{
  display: none;
}

.svgIcon {
  width: 12px;
  transition-duration: 0.3s;
}

.svgIcon path {
  fill: white;
}

.back-to-top-button:hover {
  width: 140px;
  border-radius: 50px;
  transition-duration: 0.3s;
  background-color: #13a753;
  align-items: center;
}

.back-to-top-button:hover .svgIcon {
  /* width: 20px; */
  transition-duration: 0.3s;
  transform: translateY(-200%);
}

.back-to-top-button::before {
  position: fixed;
  bottom: -20px;
  content: "Back to Top";
  color: white;
  /* transition-duration: .3s; */
  font-size: 0px;
}

.back-to-top-button:hover::before {
  font-size: 13px;
  opacity: 1;
  bottom: unset;
  /* transform: translateY(-30px); */
  transition-duration: 0.3s;
}

.CartBtn {
  /* height: 40px; */
  padding: 12px 22px;
  border-radius: 100px;
  /* border: none; */
  border: 1px solid #d9d9d9;
  background-color: #13a753;
  color: #FFFFFF;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition-duration: .5s;
  overflow: hidden;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.103);
  position: relative;
  font-weight: 500;
  column-gap: 10px;
}

.IconContainer {
  position: absolute;
  left: -50px;
  width: 30px;
  height: 30px;
  background-color: transparent;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  z-index: 2;
  transition-duration: .5s;
}

.icon {
  border-radius: 1px;
}

.text {
  /* height: 100%;
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgb(17, 17, 17);
  text-align: center; */
  /* padding: 8px 22px; */
  z-index: 1;
  transition-duration: .5s;
  font-size: 16px;
  /* font-size: 1.04em;
  font-weight: 600; */

}

.CartBtn:hover .IconContainer {
  transform: translateX(58px);
  border-radius: 40px;
  transition-duration: .5s;
}

.CartBtn:hover .text {
  transform: translate(10px,0px);
  font-size: 16px;
  transition-duration: .5s;
}

.CartBtn:active {
  transform: scale(0.95);
  transition-duration: .5s;
}
.filters {
  background-color:#e0e0e0;
  max-width: max-content;
  border-radius: 2px;
  padding: 8px;
  box-shadow: 0 2px 4px 0 hsla(0, 0%, 100%, .5);
  border-radius: 3px;
  overflow: hidden;
  transition: background-color .1s;
}

.cross_btn {
  cursor: pointer;
  background: none;
  float: right;
}
.product_items {
  border: 1px solid #d9d9d9;
  background-color: var(--white-color);
  padding: 18px 15px; 
  border-radius: 15px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;
  height: 100%; 
}
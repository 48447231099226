@media (max-width: 575.98px) {

.default_form_email input {
  width: 70vw !important;
} 
 
}
 .inner_banner .page_path {
    position: relative;
    z-index: 1;
    font-weight: 400;
    color: var(--white-color);
  }
  .forms_wrpapper {
    max-width: 800px;
    margin: auto;
  }
  .default_form input::placeholder {
    color: #9e9e9e;
    transition: 0.3s all;
  }
  .default_form input:focus::placeholder {
    opacity: 0.4;
  }
  .default_form input[type="checkbox"],
  .default_form input[type="radio"] {
    min-width: 16px;
    height: 16px;
    width: 16px !important;
    margin: inherit;
    border: 1px solid #c3c3c3;
    padding: inherit;
  }
  .default_form input[type="radio"] {
    padding: 0;
  }
  .default_form .button_wrapper {
    padding-top: 30px;
    margin-top: 30px;
    border-top: 1px solid #dcdcdc;
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 40px;
  }
  .default_form.coupon_form input {
    width: 300px;
  }
  .content-center {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    flex-direction: column !important;
  }
  .default_form_email input {
    width: 500px;
    border: 0;
    outline: none;
    background-color: #f3f4f6;
    color: #7b8488;
    font-size: 16px;
    transition: 0.3s all;
    border-radius: 7px;
    padding: 15px 25px;
    border: 1px solid #f3f4f6;
    margin-bottom: 15px;
  }
  
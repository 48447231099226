/* @media (max-width: 1599.98px) {
  .container {
    max-width: 1360px;
  }
} */

/* @media (max-width: 1399.98px) {
  .container {
    max-width: 1260px;
  }
  .search_form {
    min-width: 280px;
  }
  .navigation_bar {
    column-gap: 10px;
  }
} */
 @media(max-width: 991.98px) {
  .cart_item_count {
    position: relative;
    display: block;
  }
  .cart_item span {
    top: 2px !important;
    right: 3px !important;
    background-color: var(--theme-color);
    color: var(--white-color);
    padding: 3px 6px !important;
    border: 1px solid #6a6a6a;
    font-size: 10px !important;
    border-radius: 100px !important;
    min-width: 20px;
    position: absolute;
    height: 20px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    visibility:visible !important;
  }
  .cart_btn {
    transition: 0.2s all;
    padding: 7px 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white !important;
    text-decoration: none;
    font-size: 15px;
    border-radius: 8px;
    border: none;
  }
  .cart_icon{
    color: black;
  }
  .cart_btn {
    background: none !important;

  }
 }
.address_link {
  text-decoration: none;
  color: var(--darkblue-color) !important;
}
.address_link span {
  font-size: 15px;
}
.address_icon {
  min-width: 42px;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #d9d9d9;
  border-radius: 100px;
  padding-top: 3px;
  font-size: 20px;
}

.navigation_bar {
  display: flex;
  align-items: center;
  column-gap: 25px;
  justify-content: space-between;
}
.logo_wrapper {
  width: 125px;
}
.v_line {
  display: block;
  border-right: 1px solid #d9d9d9;
  height: 35px;
}

.menuBtn {
  transition: 0.2s all;
  min-width: 33px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #13a753;
  color: white;
  font-size: 20px;
  cursor: pointer;
  border-radius: 5px;
  padding-top: 2px;
}

.menuBtn:hover {
  opacity: 0.8;
}

.menu_modal .modal-dialog {
  max-width: 1300px;
  padding: 0px 30px;
}
.menu_modal .modal-body {
  padding: 0 !important;
}
.menu_modal .modal-header {
  padding: 0;
  border: 0;
}
.menu_modal .modal-content {
  border: 0 !important;
  border-radius: 10px;
}

.site_categories {
  max-width: 360px;
  border-right: 1px solid #dadada;
}
.site_categories > ul {
  padding: 0;
  margin: 0;
}
.site_categories > ul > li {
  list-style: none;
}

.site_categories > ul > li.opened_menu_item,
.site_categories > ul > li.opened_menu_item.active:last-child {
  border-bottom: 1px solid #dadada;
}
.site_categories > ul > li.opened_menu_item > a {
  display: inline-flex;
  align-items: center;
  column-gap: 10px;
  font-size: 16px;
  position: relative;
  font-weight: 500;
  padding: 20px 20px 16px 20px;
  border-radius: 10px;
}
.site_categories > ul > li.opened_menu_item:last-child {
  border-bottom: 0px;
}

.site_categories > ul > li.opened_menu_item > a img {
  filter: grayscale(1);
  width: 36px;
}
.site_categories > ul > li.opened_menu_item > a::after {
  position: absolute;
  font-family: "bootstrap-icons";
  font-size: 16px;
  font-weight: 700;
  content: "\F285";
  right: 10px;
  top: 50%;
  transform: translate(-50%, -50%);
}

.site_categories > ul > li > a {
  text-decoration: none;
  color: var(--darkblue-color);
  transition: 0.2s all;
  width: 100%;
}
.site_categories > ul > li > a:hover {
  color: var(--theme-color);
}

.site_categories > ul > li.opened_menu_item.active > a {
  color: var(--theme-color);
  background-color: #f5fff9;
}
.site_categories > ul > li.opened_menu_item.active > a img,
.site_categories > ul > li.opened_menu_item > a:hover img {
  filter: grayscale(0);
}

.menu_modal .btn-close {
  box-sizing: content-box;
  padding: 0.25em 0.25em;
  background: transparent center/14px auto no-repeat;
  border: 0;
  width: 25px;
  height: 25px;
  border-radius: 100px;
  opacity: 1;
  background-color: var(--theme-color);
  position: absolute;
  right: -6px;
  top: -7px;
  z-index: 1;
  border: 1px solid var(--white-color);
}

.menu_modal .modal-content,
.site_categories,
.menu_product_wrap,
.menu_product_wrap {
  height: 100%;
}

.sub_category_menu {
  transition: 0.2s all;
  position: absolute;
  left: 99%;
  padding: 0;
  margin: 0;
  padding-left: 30px;
  top: 26px;
  width: 100%;
  height: 60vh;
  visibility: hidden;
  opacity: 0;
  overflow: auto;
}
.category_menu {
  position: relative;
}

.category_menu li.opened_menu_item.active .sub_category_menu {
  opacity: 1;
  visibility: visible;
}

.sub_category_menu > li {
  width: 100%;
  list-style: none;
  margin-bottom: 12px;
}
.search_form {
  min-width: 400px;
  position: relative;
}
.search_form input {
  background: #f3f4f6;
  padding: 12px 23px;
  border-radius: 5px;
  border: 0;
  outline: none;
  width: 100%;
}
.search_form input::placeholder {
  font-size: 15px;
  color: var(--darkblue-color);
}

.search_form button {
  position: absolute;
  height: 100%;
  background-color: #f3f4f6;
  border-radius: 7px;
  padding: 5px 20px;
  font-size: 20px;
  right: 0px;
  top: 0;
  color: var(--darkblue-color);
  border: 0;
}
.navMainpage_link {
  font-size: 15px;
  transition: 0.2s all;
  text-decoration: none;
  text-align: center;
  color: var(--darkblue-color);
  font-weight: 500;
  border: none;
  background: none;
}
.navMainpage_link i {
  margin-bottom: 4px;
  display: block;
  height: 31px;
  font-size: 27px;
}
.navMainpage_link:hover {
  color: var(--theme-color);
}

.cart_btn {
  transition: 0.2s all;
  background-color: #13a753;
  padding: 7px 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white !important;
  text-decoration: none;
  font-size: 15px;
  border-radius: 8px;
  border: none;
}
.cart_icon {
  min-width: 20px;
  font-size: 24px;
}
.cart_btn:hover {
  opacity: 0.8;
}
.navbar-pos {
  position: sticky;
  top: 0px;
  background-color: white;
  z-index: 3;
}
.d-inline-block_btn{
  border: none;
  background: none;

}
.cart_item_count {
  position: relative;
}
.cart_item span {
  top: -6px;
  right: 3px;
  background-color: var(--theme-color);
  color: var(--white-color);
  padding: 3px 6px;
  border: 1px solid #6a6a6a;
  font-size: 12px;
  border-radius: 100px;
  min-width: 20px;
  position: absolute;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  /* display: none; */
  visibility: hidden;
}

@media (max-width: 575.98px) {
.dashboard_graph_boxes {
    margin: 20px;
    border: 2px solid rgb(201, 201, 201);
    border-radius: 10px;
    padding: 5px;
    width: 100% !important;
}
.sales_card {
    transition: 0.2s all;
    background-color: #13a753;
    padding: 20px 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white !important;
    text-decoration: none;
    font-size: 10px !important;
    border-radius: 6px;
    border: none;
    width: 30vw !important;
    flex-wrap: wrap;
    margin: 15px;
    flex-grow: 0;
    flex-shrink: 1;
    flex-basis: calc(25% - 1em);
    box-sizing: border-box;
    height: 15vh !important;
}
}
.sales_card{
    transition: 0.2s all;
    background-color: #13a753;
    padding: 20px 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white !important;
    text-decoration: none;
    font-size: 13px;
    border-radius: 6px;
    border: none;
    width: 15vw;
    flex-wrap: wrap;
    margin: 15px;
    flex: 0 1 calc(25% - 1em);
    box-sizing: border-box;

}
.card-boxes{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    flex: 0 1 calc(25% - 1em);
    box-sizing: border-box;

    
}
.dashboard_graphs{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}
.dashboard_graph_boxes{
    margin: 20px;
    border: 2px solid rgb(201, 201, 201);
    border-radius: 10px;
    padding: 5px;
    width: 26vw;
}

.dashboard-boxes-title{
    text-align: center;
    padding: 10px;
    font-size: 15px;
}
#Piechart{
    display:inline; 
    height: 20%;    
}

#dot_loading{
    display:inline; 
    display:'flex';
    justify-content:'center';
    align-items:'center';
    height:'160px'!important;
}

.select-dropdown {
    border: none;
    cursor: pointer; 
}
.dot_loader {
    width: 10px;
    aspect-ratio: 1;
    border-radius: 50%;
    animation: l5 3s infinite linear alternate;
    margin-top: 2vh;
  }
  @keyframes l5 {
      0%  {box-shadow: 20px 0 #ffffff, -20px 0 rgba(192, 176, 176, 0.133);background: #ffffff }
      33% {box-shadow: 20px 0 #ffffff, -20px 0 rgba(241, 237, 237, 0.133);background: rgba(192, 176, 176, 0.133)}
      66% {box-shadow: 20px 0 rgba(192, 176, 176, 0.133),-20px 0 #ffffff; background: rgba(192, 176, 176, 0.133)}
      100%{box-shadow: 20px 0 rgba(192, 176, 176, 0.133),-20px 0 #ffffff; background: #ffffff }
  }
@media (max-width: 991.98px) {
  .container {
      max-width: 100%;
  }
}
@media (max-width: 575.98px) {
  .about_bg{
        width: 100% !important;
        font-size: 10px !important;
    
  } 
  .inner_banner {
    padding: 5px !important;
}
  .team_img {
    height: 80% !important;
}
  .timeline_line .rightside_content, .timeline_line .leftside_content {
      padding-left: 90px !important;
  }
  .timeline_line {
    margin-top: 30px !important;
    position: relative !important;
    padding: 25px 0px 0px 0px !important;
}
.mobile_fs, .anchor_link.fs-16 {
  font-size: 14px !important;
}

.team_item {
  padding: 9px !important;
}
.team_item, .team_img {
  border-radius: 7px !important;
}
.fs-20 {
  font-size: 16px !important;
}
}
@media (max-width: 575.98px) {
  @media (max-width: 416.98px) {
      .team_item span.fs-14 {
          font-size: 5px !important;
      }
  }
}
@media (max-width: 991.98px) {
  .timeline_line .rightside_content {
      padding-left: 100px;
      position: relative;
      max-width: 600px;
      margin-right: auto;
      margin-left: 0;
  }
  .timeline_line .leftside_content {
    text-align: left !important;
    padding-right: 0px !important;
    padding-left: 90px !important;
    max-width: 600px !important;
}
  .timeline_line .leftside_content::before {
    left: 60px !important;
    right: inherit !important;
}
    .timeline_line::after {
        left: 0px !important;
        width: 4px !important;
    }
    .timeline_line .leftside_content::after {
      left: 0 !important;
      right: inherit !important;
  }
}
.about_bg {
  background-image: url("../../../Components/Images/airport-terminal-scaled-e1703228056729.jpg");
}

.about_content_wrapper {
  padding-left: 50px;
  padding-right: 50px;
}
.about_img {
  border-radius: 25px;
  overflow: hidden;
}
.about_img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.comapny_timeline {
  max-width: 900px;
  margin: auto;
}

/* .timeline_wrapper{
    display: flex;
    align-items: center;
    justify-content: center;
} */

.timeline_line {
  margin-top: 40px;
  position: relative;
  padding: 50px 0px 30px 0px;
}
.timeline_line::after {
  position: absolute;
  content: "";
  left: 50%;
  top: 0;
  transform: translate(-50%, 0);
  width: 6px;
  height: 100%;
  background-color: var(--black-color);
  border-radius: 100px;
}
.timeline_line .rightside_content {
  padding-left: 100px;
  position: relative;
  max-width: 447px;
  margin-left: auto;
  margin-bottom: 40px;
}
.timeline_line .rightside_content::after {
  position: absolute;
  content: "";
  left: 0;
  top: 10px;
  display: block;
  width: 70px;
  height: 2px;
  background-color: var(--black-color);
}
.timeline_line .rightside_content::before {
  content: "";
  position: absolute;
  top: 2px;
  z-index: 1;
  left: 60px;
  width: 17px;
  height: 17px;
  background-color: #fcf4f0;
  border: 1px solid var(--black-color);
  border-radius: 100px;
}

.timeline_line .leftside_content {
  text-align: right;
  padding-right: 100px;
  position: relative;
  max-width: 447px;
  margin-right: auto;
  margin-bottom: 40px;
}
.timeline_line .leftside_content::after {
  position: absolute;
  content: "";
  right: 0;
  top: 10px;
  display: block;
  width: 70px;
  height: 2px;
  background-color: var(--black-color);
}
.timeline_line .leftside_content::before {
  content: "";
  position: absolute;
  top: 2px;
  z-index: 1;
  right: 60px;
  width: 17px;
  height: 17px;
  background-color: #fcf4f0;
  border: 1px solid var(--black-color);
  border-radius: 100px;
}

.max_content {
  max-width: 900px;
  margin: auto;
}
.fs-20 {
  font-size: 20px;
}
.team_item {
  height: 100%;
  display: block;
  border: 1px solid var(--grey-color);
  padding: 12px;
}
.team_item,
.team_img {
  border-radius: 10px;
  overflow: hidden;
}
.team_img {
  height: 400px;
}
.team_img img {
  transition: 0.3s all;
  height: 100%;
  object-fit: cover;
  width: 100%;
}
.team_item:hover .team_img img {
  transform: scale(1.1);
}
.linkedin_icon {
  float: right;
}
.pos-mid {
  display: flex;
  justify-content: center;
  gap: 13px;
}

@media (max-width: 991.98px) {
    .work_process {
        justify-content: center;
        column-gap: 90px;
    }
    .work_process_wrap, .connect_info {
        text-align: center;
    }
}

@media (max-width: 575.98px) {
    .work_process {
        column-gap: 40px !important;
    }
    .work_process_wrap , .connect_info {
        text-align: center;
    }
}

.map_wrap{
    border-radius: 15px;
    overflow: hidden;
}
.map_wrap video{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.work_process {
    display: flex;
    align-items: flex-start;
    column-gap: 90px;
}
.workProcess_item{
    position: relative;
}
.work_process_wrap {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.workProcess_item::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 1px;
    top: 12px;
    left: 60px;
    border-top: 1px dashed var(--theme-color);
}
.work_process .workProcess_item:last-child:after{
    display: none;
}
.connect_info i , .connect_info a h4{
    color: #0B5676;
}
.connect_info a :hover{
    text-decoration: underline !important;
}

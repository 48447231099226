@media (max-width: 991.98px) {
  .container {
      max-width: 100%;
  }
}

@media (max-width: 575.98px) {
  @media (max-width: 416.98px) {
      .bordered_btn,.animated-view-more .product_name, .product_price, .product_current_price {
          font-size: 13px !important;
      }
      
  }
}
@media (max-width: 575.98px) {
  .product_img img {
      object-fit: cover;
      height: 65% !important;
  }
  .product_item{
    height: 90% !important;
    padding: 10px 10px !important;
    margin:  0px -8px 0px -8px;
  }
  
  .bordered_btn{
    border-radius: 40px !important;
    font-size: 10px !important;
    padding: 5px 8px !important;

  
  }
  .product_name {
        font-size: 14px !important;
  }
  .CartBtn{
    border-radius: 15px !important;
    font-size: 10px !important;
    padding: 2px 4px;

  }
  .text{
    font-size: 9px !important;
  }
  .animated-view-more {
    border-radius: 15px !important;
    font-size: 10px !important;
    padding: 2px 4px;
  }
  .printedWeight{
    display: none;
   

  }
  .excl-gst-price {
    color: var(--theme-color);
    font-size: 9px !important;   
}
.excl-gst-text, .mrp-text {
  font-size: small;
  font-size: 9px !important;   

}

  /* .paddingBottom {
    padding: 0px !important;
  } */

}

.product_item {
  border: 1px solid #d9d9d9;
  background-color: var(--white-color);
  padding: 18px 15px;
  border-radius: 15px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;
  height: 100%;
}

.product_img {
  display: block;
  height: 305px;
  border-radius: 15px;
  overflow: hidden;
  width: 100%;
}
.product_img img {
  transition: 0.6s all;
  border-radius: 15px;
  width: 100%;
  height: 75%;
  object-fit: cover;
}

.product_content {
  width: 100%;
  margin: 15px 0px 15px 0px;
}
.product_name {
  transition: 0.2s all;
  text-decoration: none;
  display: block;
  font-size: 16px;
  color: var(--black-color);
  margin-bottom: 9px;
}
.product_name:hover {
  /* text-decoration: underline; */
  /* color: var(--theme-color); */
  cursor: pointer;
}
.product_rating {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  column-gap: 2px;
}
.product_rating span i {
  color: #ffae01;
  font-size: 15px;
}
.product_price {
  color: #f4424f;
}
.product_price span {
  margin-right: 5px;
  text-decoration: line-through;
}
.product_current_price {
  /* font-weight: 500; */

  word-break: break-word;
}

.pp_product {
  height: 540px;
  border-radius: 15px;
  overflow: hidden;
}
.pp_product img {
  transition: 0.5s all;
  height: 100%;
  object-fit: cover;
}
.product_img img:hover,
.pp_product img:hover {
  transition: 0.5s all;
  transform: scale(1.2);
}

.map_wrap {
  border-radius: 15px;
  overflow: hidden;
}
.map_wrap img {
  height: 100%;
  object-fit: cover;
}
.work_process {
  display: flex;
  align-items: flex-start;
  column-gap: 90px;
}
.workProcess_item {
  position: relative;
}
.work_process_wrap {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.workProcess_item::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 1px;
  top: 12px;
  left: 60px;
  border-top: 1px dashed var(--theme-color);
}
.work_process .workProcess_item:last-child:after {
  display: none;
}
.connect_info i,
.connect_info a h4 {
  color: #0b5676;
}
.connect_info a :hover {
  text-decoration: underline !important;
}
.bordered_btn {
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 10px;
  transition: 0.2s all;
  padding: 8px 22px;
  color: var(--black-color);
  border-radius: 100px;
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  text-decoration: none;
  text-transform: capitalize;
  border: 1px solid #d9d9d9;
  background-color: var(--white-color);
}
.bordered_btn:hover {
  color: var(--black-color);
  border-color: var(--grey-color);
  background-color: #fafafa;
}
.bordered_btn i {
  font-size: 21px;
  color: var(--black-color);
}
.bordered_btn:hover .add-to-cart-input {
  background-color: #fafafa;
}

.excl-gst-price {
  color: var(--theme-color);
  font-weight: 600;
}

.excl-gst-text,
.mrp-text {
  font-size: small;
}
.carousel-ltr .carousel-item-next,
.carousel-ltr .carousel-item-prev,
.carousel-ltr .carousel-item.active {
  transform: translateX(0); /* Adjust as needed */
}

.animated-view-more {
  cursor: pointer;
  position: relative;
  padding: 8px 22px;
  font-size: 16px;
  color: #222222;
  border: 1px solid #d9d9d9;
  border-radius: 100px;
  background-color: #ffffff;
  font-weight: 500;
  transition: all 0.3s cubic-bezier(0.23, 1, 0.320, 1);
  overflow: hidden;
  text-transform: capitalize;
  text-align: center;
  margin-right: 1%;
}

.animated-view-more::before {
  content: '';
  position: absolute;
  inset: 0;
  margin: auto;
  width: 50px;
  height: 50px;
  border-radius: inherit;
  scale: 0;
  z-index: -1;
  background-color: #13A753;
  transition: all 0.6s cubic-bezier(0.23, 1, 0.320, 1);
}

.animated-view-more:hover::before {
  scale: 3;
}

.animated-view-more:hover {
  color: #FFFFFF;
  scale: 1.1;
}

.animated-view-more:active {
  scale: 1;
}



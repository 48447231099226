@media (max-width: 991.98px) {

.inner_banner .heading_content_wrap {
    padding: -5px 0px 140px 0px !important;
    position: relative;
    z-index: 1;
    color: var(--white-color);
}
.terms_bg{
    background-image: url("../../../Components/Images/inner-bg3.jpg");
}
}
@media (max-width:575.98px) {
    .inner_banner .heading_content_wrap {
        padding: -5px 0px 140px 0px !important;
        position: relative;
        z-index: 1;
        color: var(--white-color);
    }
}

.terms_bg{
    background-image: url("../../../Components/Images/inner-bg3.jpg");
}
.inner_banner {
    padding: 30px;
    border-radius: 25px;
    overflow: hidden;
    position: relative;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.inner_banner {
    padding: 30px;
    border-radius: 25px;
    overflow: hidden;
    position: relative;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.inner_banner .page_path{
    position: relative;
    z-index: 1;
    font-weight: 400;
    color: var(--white-color);
}

.inner_banner .page_path a{
    color: #bfbfbf;
}

.inner_banner .heading_content_wrap{
    padding: 100px 0px 140px 0px;
    position: relative;
    z-index: 1;
    color: var(--white-color);
}
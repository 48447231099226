@media (max-width: 575.98px) {
  .about_bg{
        width: 100% !important;
        font-size: 10px !important;
    
  } 
}

@media (max-width: 575.98px) {
  .card.is-loading image  {
      object-fit: cover;
      height: 65% !important;
  }
  .card.is-loading {
    width: 35vw;
}
  .card.is-loading{
    height: 90% !important;
    padding: 10px 10px !important;
    margin:  0px 1px 0px -14px;
    border-radius: 8px;

  }
}
.cards {
    display: flex;
  }
  
  .card {
    margin: 10px;
    width: 230px;
    background: #fff;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
  
    .image {
      img {
        max-width: 100%;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
      }
    }
  
    .content {
      padding: 20px 30px;
    }
  }
  
  .card.is-loading {
    .image,
    h2,
    p {
      background: #eee;
      background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
      border-radius: 5px;
      background-size: 200% 100%;
      animation: 1.5s shine linear infinite;
    }
  
    .image {
      height: 200px;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  
    h2 {
      height: 30px;
    }
  
    p {
      height: 70px;
    }
  }
  
  @keyframes shine {
    to {
      background-position-x: -200%;
    }
  }
  .banner-cards {
    margin: 10px;
    width: 230px;
    background: #fff;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
  
    .image {
      img {
        max-width: 100%;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
      }
    }
  
    .content {
      padding: 20px 30px;
    }
  }
.profile_wrap{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    column-gap: 15px;
}
.profile_img{
    width: 80px;
    height: 80px;
    border-radius: 100px;
    overflow: hidden;
    border: 1px solid #DCDCDC;
}
.myAccount_links_wrap {
    min-width: 300px;
}
.myAccount_links_wrap .nav-link{
    font-weight: 500;
    color: var(--black-color);
    padding: 14px 20px;
    text-align: start;
    border: 1px solid #DCDCDC;
    margin-top: -1px;
    border-radius: 0px;
}
.myAccount_links_wrap.nav-pills .nav-link.active{
    background-color: transparent;
    color: var(--theme-color);
}
.anchor_link.fs-16{
    font-size: 16px;
    font-weight: 400;
}
.user_img{
    object-fit: cover;
    height: 90px;
}
.change_password{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row
}
.add-btn{
    float: right;
}
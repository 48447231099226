
.product-img{
    width: 100px;
    height: 100px;
    transform: scale(.7);
}

.approved-status {
    background-color: green;
    color: white;
    font-weight: bold;
    text-align: center;
    border-radius: 5px;
    width: 10vh;
    padding: 1%;
}

.pending-status {
    background-color: orange;
    color: white;
    font-weight: bold;
    text-align: center;
    border-radius: 5px;
    width: 9vh;
    padding: 1%;
}
.order_id_div{
    color: lightseagreen
}
.no-product-found-text{
    display: flex;
    text-align: center;
    justify-content: center;
}
.password_container{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
    background-color: white;
}

.password_inner_container{
    display: flex;
    flex-direction: column;
    background-color: white;
    padding: 4%;
    /* border-radius: 20px; */
}

.logo{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 40px;
}
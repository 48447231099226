.page_path {
  font-size: 16px;
  margin-bottom: 0px;
  margin-top: 20px;
  font-weight: 500;
}
.page_path a {
  font-weight: 400;
  color: #7f7f7f;
  text-decoration: none;
}
.page_path a:hover {
  color: var(--theme-color);
}
.inner_banner .page_path {
  position: relative;
  z-index: 1;
  font-weight: 400;
  color: var(--white-color);
}

.inner_banner .page_path a {
  color: #bfbfbf;
}
.search_form {
  min-width: 400px;
  position: relative;
}
.search_form input {
  background: #f3f4f6;
  padding: 12px 23px;
  border-radius: 5px;
  border: 0;
  outline: none;
  width: 100%;
}
.search_form input::placeholder {
  font-size: 15px;
  color: var(--darkblue-color);
}
.search_form button {
  position: absolute;
  height: 100%;
  background-color: #f3f4f6;
  border-radius: 7px;
  padding: 5px 20px;
  font-size: 20px;
  right: 0px;
  top: 0;
  color: var(--darkblue-color);
  border: 0;
}
.scrollbar {
  overflow-y: scroll;
}

.scrollbar::-webkit-scrollbar-track {
  background-color: var(--white-color);
  border-radius: 100px;
}

.scrollbar::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

.scrollbar::-webkit-scrollbar-thumb {
  background-color: #464646;
  border-radius: 100px;
}
.default_table.table > :not(caption) > * > * {
  vertical-align: middle;
  padding: 20px 15px;
}
.default_table {
  min-width: 800px;
}
.default_table tbody tr td .quantity-number {
  width: 160px;
  padding: 12px 12px;
}

.all-members-icons {
  position: absolute;
  display: flex;
  flex-direction: column;
}
.all-members-icons i {
  font-size: large;
}
/* .heading-wrapper {
  margin-right: 10%;
} */
.table-wrapper {
  width: fit-content;
}

.page_path {
  font-size: 16px;
  margin-bottom: 0px;
  margin-top: 20px;
  font-weight: 500;
}
.page_path a {
  font-weight: 400;
  color: #7f7f7f;
  text-decoration: none;
}
.page_path a:hover {
  color: var(--theme-color);
}
.inner_banner .page_path {
  position: relative;
  z-index: 1;
  font-weight: 400;
  color: var(--white-color);
}
.inner_banner .page_path a {
  color: #bfbfbf;
}
.forms_wrpapper {
  max-width: 800px;
  margin: auto;
}
.paddingTop {
  padding-top: 50px;
}
.paddingBottom {
  padding-bottom: 50px;
}
.default_tabs .nav-pills .nav-link {
  font-size: 25px;
  font-weight: bold;
  color: #c3c3c3;
}
.default_tabs .nav-pills .nav-link.active {
  cursor: default !important;
  /* color: #fff; */
  /* background-color: transparent; */
  color: var(--black-color);
}
.default_tabs .nav-pills {
  column-gap: 35px;
}
.myAccount_links_wrap.nav-pills .nav-link.active {
  background-color: transparent;
  color: var(--theme-color);
}
.description_tabs .nav-pills .nav-link {
  margin-right: 10px;
  color: var(--black-color);
  border: 1px solid #d9d9d9;
  border-radius: 10px;
  font-weight: 500;
}
.description_tabs .nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  border-color: var(--theme-color);
  color: var(--white-color);
  background-color: var(--theme-color);
}

.myAccount_links_wrap .nav-link {
  font-weight: 500;
  color: var(--black-color);
  padding: 14px 20px;
  text-align: flex;
  border: 1px solid #dcdcdc;
  margin-top: -1px;
  border-radius: 0px;
}
.default_form input,
.default_form select,
.default_form textarea {
  border: 0;
  outline: none;
  background-color: #f3f4f6;
  color: #7b8488;
  font-size: 16px;
  transition: 0.3s all;
  border-radius: 7px;
  width: 100%;
  padding: 15px 25px;
  border: 1px solid #f3f4f6;
  margin-bottom: 15px;
}
.default_form input::placeholder {
  color: #9e9e9e;
  transition: 0.3s all;
}
.default_form input:focus::placeholder {
  opacity: 0.4;
}
.default_form input[type="checkbox"],
.default_form input[type="radio"] {
  min-width: 16px;
  height: 16px;
  width: 16px !important;
  margin: inherit;
  border: 1px solid #c3c3c3;
  padding: inherit;
}
.default_form input[type="radio"] {
  padding: 0;
}
.default_form .button_wrapper {
  padding-top: 30px;
  margin-top: 30px;
  border-top: 1px solid #dcdcdc;
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 40px;
}
.default_form.coupon_form input {
  width: 300px;
}
.totalPrice_wrap .default_form.coupon_form {
  padding: 20px 0px;
  margin-bottom: 20px;
  border-top: 1px solid #d0d0d0;
  border-bottom: 1px solid #d0d0d0;
}
.search_form input {
  background: #f3f4f6;
  padding: 12px 23px;
  border-radius: 5px;
  border: 0;
  outline: none;
  width: 100%;
}
.subscribe_form input {
  border: 0;
  outline: none;
  background-color: #f3f4f6;
  color: #7b8488;
  font-size: 16px;
  min-width: 500px;
}
.quantity-number input {
  color: var(--black-color);
  margin: 0px 10px;
  width: 60px;
  border: 0px;
  font-weight: 500;
  font-size: 16px;
}
.role_wrap label input {
  border: 1px solid #c0c0c0;
  background: var(--white-color);
  cursor: pointer !important;
}
.default_form.coupon_form input {
  width: 300px;
}
.remember_para {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.form-check-input:checked {
  box-shadow: none;
  background-color: var(--theme-color);
  border-color: var(--theme-color);
}
.form-check-input:checked label {
  border-color: var(--theme-color);
}
#filterAccordion .accordion-body .form-check {
  margin-bottom: 5px;
}
#filterAccordion .accordion-body .form-check:last-child {
  margin-bottom: 0px;
}
#filterAccordion .accordion-body .form-check label {
  text-transform: capitalize;
  transition: 0.2s all;
  cursor: pointer !important;
  display: inline-flex;
  align-items: center;
  column-gap: 10px;
  color: var(--grey-color);
}
#filterAccordion .accordion-body .form-check label:hover {
  color: var(--black-color);
}
#filterAccordion .accordion-body .form-check label .form-check-input {
  border-color: #bebebe;
  box-shadow: none;
  width: 20px;
  height: 20px;
  margin-top: -2px;
}
#filterAccordion .accordion-body .form-check label .form-check-input:checked {
  background-color: var(--theme-color);
  border: 0px;
}
.fs-14 {
  font-size: 14px;
}
.form-check-input:checked {
  box-shadow: none;
  background-color: var(--theme-color);
  border-color: var(--theme-color);
}
.form-check-input:checked label {
  border-color: var(--theme-color);
}
.anchor_link {
  color: var(--theme-color);
  font-size: 14px;
  text-decoration: none;
  transition: 0.2s all;
  font-weight: 500;
}
.anchor_link:hover {
  color: var(--theme-color);
  text-decoration: underline;
}
.anchor_link.fs-16 {
  font-size: 16px;
  font-weight: 400;
}
.theme_btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  column-gap: 10px;
  transition: 0.2s all;
  text-decoration: none;
  border: 0;
  outline: none;
  background-color: var(--theme-color);
  border-radius: 10px;
  font-size: 16px;
  font-weight: 500;
  text-transform: capitalize;
  text-align: center;
  color: var(--white-color);
  padding: 10px 23px;
}
.theme_btn:hover {
  opacity: 0.8;
  color: var(--white-color);
}
.shortBy_wrapper select {
  cursor: pointer !important;
  font-weight: 500;
}
.default_form input,
.default_form select {
  border: 0;
  outline: none;
  background-color: #f3f4f6;
  color: #7b8488;
  font-size: 16px;
  transition: 0.3s all;
  border-radius: 7px;
  width: 100%;
  padding: 15px 25px;
  border: 1px solid #f3f4f6;
  margin-bottom: 15px;
}
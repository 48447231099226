.po-btn{
width: 219.66px;
height: 44px;
left: 1130.35px;
top: 176.36px;
background: #13A753;
border-radius: 10px;
}
.download_icon{
    min-width: 20px;
    font-size: 20px;
}
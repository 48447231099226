@media (max-width: 575.98px) {
    .slideProduct_item {
        padding: 20px 15px !important;
        height: 245px !important;
        margin: 3px;
    }
    .slideProduct_item img {
        height: 150px !important;
    }
  }

.slideProduct_item {
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-decoration: none;
    color: var(--black-color);
    transition: .2s all;
    text-align: center;
    padding: 25px 20px;
    border: 1px solid #D9D9D9;
    background-color: var(--white-color);
    /* margin: 0px -1px; */
    height: 305px;
    border-radius: 4%;
}
.slideProduct_item:hover{
    color: var(--theme-color);
    transform: translateY(-20px);
}
.slideProduct_name{
    font-size: 16px;
    font-weight: 400;
    margin-top: 18px;
    margin-bottom: 0px;
    text-transform: capitalize;
}

.slideProduct_item img {
    height: 190px;
    object-fit: contain;
}
.product_slider{
    position: relative;
    padding-left: 2px;
    padding-right: 1px;
}
.product_slider .swiper-pagination {
    bottom: -10px;
}
.payemnt_page {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.payment_page_inner_box{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.payment_page_inner_box button{
    margin-top: 5%;
    width: fit-content;
}
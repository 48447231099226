/* @media (max-width: 1299.98px){
.home-container {
    max-width: 1160px;
}
.hero_slider .swiper-slide img {
    height: 350px;
}
} */
@media (max-width: 991.98px) {
    .container {
        max-width: 100%;
    }
    .hero_slider .swiper-slide img {
        height: 45% !important;
        width: 100% !important;
        aspect-ratio: 2 / 1  !important;
        border-radius: 10px !important;

    }
    
}
  


/* @media (max-width: 1299.98px) { 

    .container {
        max-width: 1160px;
    }
    .hero_slider .swiper-slide img {
        height: 350px;
    }
    .heroSlider_content {
        left: 35px;
    }
}
@media (max-width: 1599.98px) { 
    .hero_slider .swiper-slide img {
         height: 450px;
    }
    .heroSlider_content h1{
        font-size: 35px;
    }
    .heroSlider_content h4{
        font-size: 20px;
    }
}
@media (max-width: 1399.98px) {  
    .hero_slider .swiper-slide img {
        height: 410px;
    }
    .heroSlider_content h1  , .hero_slider.productPage_slider .heroSlider_content h1{
        font-size: 30px;
    }
    .heroSlider_content h4 , .hero_slider.productPage_slider .heroSlider_content h4 {
        font-size: 18px;
    }
}
@media (max-width: 991.98px) { 
    .container {
        max-width: 100%;
    }
    .hero_slider .swiper-slide {
        border-radius: 15px;
    }
}
@media (max-width: 767.98px) { 
    .heroSlider_content h1 , .hero_slider.productPage_slider .heroSlider_content h1 {
        font-size: 25px;
    }
    .heroSlider_content h4 , .hero_slider.productPage_slider .heroSlider_content h4 {
        font-size: 16px;
    }
    .hero_slider .swiper-slide img {
        height: 260px;
    }
    .hero_slider .swiper-slide {
        border-radius: 10px;
        overflow: hidden;
        position: relative;
    }
}
@media (max-width: 575.98px) {
    .heroSlider_content {
        left: 18px;
    }
    .hero_slider.productPage_slider .swiper-slide img {
        height: 240px;
    }
} */
.hero_slider{
    position: relative;
}
.hero_slider .swiper-slide{
    border-radius: 25px;
    overflow: hidden;
    position: relative;
}
.hero_slider .swiper-slide img {
    height: 500px;
    object-fit: fill;
    /* aspect-ratio: auto; */
}
.swiper-pagination-bullet{
    width: 9px;
    height: 9px;
    border: 1px solid var(--black-color) !important;
    opacity: 1;
    background-color: var(--white-color);
}
.swiper-pagination-bullet.swiper-pagination-bullet-active {
    background: var(--theme-color);
    border: 0 !important;
}
.swiper-pagination {
    left: 50% !important;
    transform: translate(-50%, -50%);
    z-index: 10;
    width: fit-content !important;
    background: var(--white-color);
    padding: 0px 8px 1px;
    border-radius: 100px;
}

.heroSlider_content {
    padding-right: 15px;
    position: absolute;
    left: 50px;
    top: 50%;
    transform: translate(0px, -50%);
}

.heroSlider_content_desc {
    padding-right: 15px;
    position: absolute;
    left: 50px;
    top: 50%;
    transform: translate(0px, -50%);
}

.theme_btn{
    display: inline-flex;
    align-items: center;
    justify-content: center;
    column-gap: 10px;
    transition: .2s all;
    text-decoration: none;
    border: 0;
    outline: none;
    background-color: #13A753;
    border-radius: 10px;
    font-size: 16px;
    font-weight: 500;
    text-transform: capitalize;
    text-align: center;
    color: white;
    padding: 10px 23px;
}
.theme_btn:hover{
    opacity: .8;
    color: white;
}
.banner{
    cursor: pointer;
}
.tg{
   border-collapse:collapse;
   border-spacing:0;
   border-color:#ccc;
}
.tg td{
  font-family:Arial, sans-serif;
  font-size:14px;padding:10px 5px;
  border-style:solid;
  border-width:0px;
  overflow:hidden;
  word-break:normal;
  border-color:#ccc;
  color:#333;
  background-color:#fff;
}
.tg th{
  font-family:Arial, sans-serif;
  font-size:14px;
  font-weight:normal;
  padding:10px 5px;
  border-style:solid;
  border-width:0px;
  overflow:hidden;
  word-break:normal;
  border-color:#ccc;
  color:#333;
  background-color:#f0f0f0;
}

.line{
    float: left;
    width: 100px;
    height: 16px;
    margin-top: 12px;
    border-radius: 7px;
    
    /* @include background-gradient; */
    background-image: linear-gradient(90deg, #F4F4F4 0px, rgba(229,229,229,0.8) 40px, #F4F4F4 80px);
    background-size: 600px;
    animation: shine-lines infinite ease-out 1.5s;
}

 
@keyframes shine-lines{
    0% { background-position: -100px;}
    40%, 100% {background-position: 140px;}
}


@media (max-width: 991.98px) {
  .category_item .swiper-slide img{
      height: 140px !important;
  }
  .category_item .swiper-slide{
    border-radius: 10px !important;
    overflow: hidden;
    position: relative;
    object-fit: fill;
  
  }
  .brochure-wrapper{
    display: none;
  }
  .category {
    display: block !important;
  }
  .category_item img {
    height: 80% !important;
    object-fit: cover;
    border-radius: 8px;
  }
}
.category_item{
  position: relative;
  transition: 0.2s all
}
.category_item .swiper-slide{
  border-radius: 25px;
  overflow: hidden;
  position: relative;
  object-fit: fill;

}
.category_item .swiper-slide img {
  height: 300px;
  object-fit: cover !important;
  transition: 0.2s all;
  color: var(--black-color);


}
.category_item h5 {
  bottom: 15px;
  left: 30px;
  position: absolute;
  transition: all .2s;
  z-index: 1;
}

/* .category_item {
  text-decoration: none;
  color: var(--black-color);
  height: 300px;
  position: relative;
  overflow: hidden;
  transition: 0.2s all;
  border: none;
  border-radius: 25px;
} */
.category_item.smallSize_category {
  height: 200px;
  
}
.category_item img {
  width: 100%;
  height: 100%;
  object-fit: cover !important;
  border-radius: 15px;
  transition: 0.2s all;
}
.category_item::after {
  transition: 0.2s all;
  content: "";
  width: 100%;
  height: 215px;
  /* background-color: var(--white-color);
  background-image: linear-gradient(0deg, #fff, #ffffff00); */
  opacity: 0.4;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 0;
}
.category_item.smallSize_category::after {
  height: 100px;
}

.category_item h5 {
  transition: 0.2s all;
  position: absolute;
  z-index: 1;
  top: 8vh;
  left: 30px;
}

.category_item:hover::after {
  opacity: 0.6;
}

.cat-btn {
  border: none;
}

.cat-btn::after {
  transition: 0.2s all;
  content: "";
  width: 100%;
  height: 215px;
  /* background-color: var(--white-color); */
  background-image: linear-gradient(0deg, #fff, #ffffff00);
  opacity: 0.4;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 0;
}
.cat-btn:hover::after {
  opacity: 0.6;
}
.cat-btn img:hover , .cat-btn img:hover{
    transition: .3s all;
    transform: scale(1.1);
}
.brochure-wrapper{
  cursor: pointer;
}
.category{
  display: none;
}